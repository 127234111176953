import * as React from "react";
import styled from "styled-components";

import { breakpoints, widths } from "../styles/variables";

const StyledContainer = styled.div`
  position: relative;
  margin: 0 16px;
  width: auto;
  max-width: ${widths.xl}px;

  @media screen and (min-width: ${breakpoints.xl}px) {
    margin: 0 auto;
  }
`;

interface ContainerProps {
  className?: string;
}

const Container: React.FC<ContainerProps> = ({ children, className }) => (
  <StyledContainer className={className}>{children}</StyledContainer>
);

export default Container;
